.slick-prev, .slick-next {
  background-color: rgba(0, 0, 0, 0.5); /* Grey circle */
  border-radius: 30%;
  width: 30px;
  height: 30px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%; /* Position in the middle vertically */
  transform: translateY(-50%); /* Center vertically */
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  color: white;
  opacity: 1;
  font-family: 'Arial', sans-serif;
}

.slick-prev:hover, .slick-next:hover,
.slick-prev:focus, .slick-next:focus {
  background-color: rgba(0, 0, 0, 0.7); /* Darken the background on hover and focus */
  outline: none; /* Ensure no outline appears on focus */
}

.slick-prev {
  left: -15px; /* Adjust to move arrow to the left side of the slider */
}

.slick-next {
  right: -15px; /* Adjust to move arrow to the right side of the slider */
}
.slick-dots {
  position: relative; /* Use relative positioning */
  top: -10px; /* Adjust this value to move dots closer to the cards */
  display: flex !important;
  justify-content: center;
  margin-top: 10px; /* Adjust this value if necessary */
}

.slick-dots li button:before {
  font-size: 12px; /* Adjust the size of the dots */
  color: #000; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Active dot color */
}

.slider-card {
  margin: 15px; /* Space between the cards */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  border-radius: 12px; /* Rounded corners */
  overflow: hidden; /* Prevent overflow */
  text-align: center; /* Center content */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  background-color: #fff; /* Ensure a clean, white background */
  height: 350px; /* Fixed height for all cards */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Distribute space evenly */
  
}

.slider-card:hover {
  transform: translateY(-5px); /* Slightly lift the card on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Increase the shadow on hover for a more dynamic effect */
}

.slick-slide {
  display: flex;
  justify-content: center; /* Center the card in the slide */
}

.card-media {
  width: 100%; /* Ensure the image spans the full width of the card */
  height: 150px; /* Set a fixed height to standardize the image size */
  object-fit: cover; /* Ensure the image covers the defined area without distortion */
  display: block; /* Display the image as a block-level element */
}

.card-media img {
  width: 80%; /* Ensure the image spans the full width of the card */
  height: 150px; /* Set a fixed height to standardize the image size */
  object-fit: cover; /* Ensure the image covers the defined area without distortion */
  display: block; /* Display the image as a block-level element */
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto; /* Center the image horizontally */
}

.no-underline-card { 
  text-decoration: none;
}

.card-content {
  margin-top: 10px; /* Reduced margin to bring text closer to image */
  padding: 10px; /* Added padding to control the space inside the content */
  flex-grow: 1; /* Ensures that content grows without affecting the button */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure the title stays close to the image */
}
