.share-buttons {
    position: relative; /* Position relative to the grid item */
    float: none; 
    margin: 0 auto;
    bottom: 0px; /* Adjust to place it near the bottom right corner */
    right: 8px;
    display: flex;
    gap: 3px; /* Spacing between buttons */
  }
  
  .share-buttons .MuiIconButton-root {
    padding: 2px; /* Keep the buttons small */
  }
  