.invest-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .invest-page h3 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.3em;
  }
  
  
  .invest-tool-card {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .invest-tool-card:hover {
    transform: translateY(-5px);
  }
  
  .tool-image {
    width: 50%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .invest-tool-link {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #ffc107;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .invest-tool-link:hover {
    background-color: #c07b05;
  }

  .invest-content {
    color: #333; /* Base text color */
    font-family: 'Arial', sans-serif; /* Base font family */
  }
  
  /* Target all paragraphs inside .invest-content */
  .invest-content p {
    font-size: 1.1em; /* Adjust the font size */
    line-height: 1.6; /* Improve readability */
    margin-bottom: 20px; /* Space between paragraphs */
  }
  
  /* Target all headings inside .invest-content */
  .invest-content h1, .invest-content h2, .invest-content h3, .invest-content h4, .invest-content h5, .invest-content h6 {
    font-size: 1.0em; /* Adjust heading size */
    color:  #333; /* Ensure heading color applies */
    margin-top: 0px; /* Add space above headings */
  }
  
  /* Target links inside .invest-content */
  .invest-content a {
    color: #ff6347; /* Change link color */
    text-decoration: underline; /* Underline links */
  }
  
  .invest-content a:hover {
    color: #e60000; /* Change color on hover */
  }
  
  /* Target strong (bold) and em (italic) tags */
  .invest-content strong {
    font-weight: bold;
  }
  
  .invest-content em {
    font-style: italic;
  }
  

  .insurance-calculator-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    text-align: center;
    color: #333;
  }
  
  .calculator-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .form-section h4 {
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .label, .input-field {
    display: block;
    width: 100%;
    margin: 8px 0;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .input-field {
    max-width: 100%;
    margin-top: 5px;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .results {
    margin-top: 30px;
    text-align: center;
  }
  
  .results h4 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .results p {
    font-size: 18px;
    color: #666;
  }
  