/* Comments.css */
.comments-section {
    width: 80%;
    margin: 0;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left; /* Align the content to the left */
  }
  
  .comments-section h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    text-align: left; /* Align the heading to the left */
  }
  
  .comments-section form {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align the form to the left */
  }
  
  .comments-section textarea {
    width: 90%;
    height: 40px;
    padding: 10px;
    margin-bottom: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }
  
  .comments-section button[type="submit"] {
    width: 150px;
    padding: 10px;
    background: #ffcc00;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .comments-section button[type="submit"]:hover {
    background: #ffa500;
  }
  
  .comments-list {
    list-style: none;
    padding: 0;
  }
  
  .comment {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .comment strong {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  
  .comment-votes {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .comment-votes button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    transition: transform 0.2s ease;
  }
  
  .comment-votes button:hover {
    transform: scale(1.2);
  }
  
  .comment-votes button:focus {
    outline: none;
  }
  
  .comment-votes span {
    font-size: 14px;
    margin: 0 10px;
    color: #333;
  }
  
  .comment-votes button svg {
    width: 16px;
    height: 16px;
    fill: #333;
    transition: fill 0.3s ease;
  }
  
  .comment-votes button:hover svg {
    fill: #ffa500;
  }
  