.article-content {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .article-body {
    padding: 10px !important;
    background-color: #fff !important;
    margin-bottom: 0px !important;
    line-height: 1.6 !important;
    border-radius: 8px !important;
    font-size: 0.1rem !important;
    color: #333 !important;
  }
  
  
  .ranking-section {
    margin-top: 40px;
  }
  
  .ranking-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
  }
  
  .ranking-table th,
  .ranking-table td {
    padding: 15px;
    border: 1px solid #ddd;
  }
  
  .ranking-table th {
    background-color: #f5f5f5;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  
  .ranking-table td {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .article-content {
        padding: 15px;
      }
    
      /* Reduce font size for article body on mobile */
      .article-body {
        font-size: 4px; /* Reduce font size for mobile */
      }
    .ranking-table {
      width: 80%;
      display: block;
      white-space: nowrap;
    }
  
    .ranking-table th, 
    .ranking-table td {
      padding: 10px 8px;
      font-size: 14px;
    }
  
    .ranking-section {
      margin-top: 10px;
    }
  }
  