body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background: #ffc107;
  color: white;
  padding: 0em 0;
  position: relative;
  z-index: 1000; /* Ensure the navbar is on top */
}
/* ResetPassword styles */
.reset-password-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.reset-password-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.reset-password-container form {
  display: flex;
  flex-direction: column;
}

.reset-password-container form div {
  margin-bottom: 15px;
}

.reset-password-container form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.reset-password-container form input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

.reset-password-container form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.reset-password-container form button:hover {
  background-color: #0056b3;
}

.reset-password-container p {
  text-align: center;
  font-size: 14px;
  color: #ff0000;
}
/* Specific styles for the h2 heading */
.form-container h2 {
  font-size: 1.5em; /* Adjust the size as needed */
}

.form-container h4 {
  font-size: 1em; /* Adjust the size as needed */
}

.logo h1 {
  font-size: 2em; /* Adjust the size as needed */
}

.rankings-container h4 {
  font-size: 1.5em; /* Adjust the size as needed */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1em;
}

.nav-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 1em;
}

.nav-links a {
  color: white;
  text-decoration: none;
  z-index: 1001; /* Ensure links are clickable */
}

.logout-button {
  background: #ffc107;
  border: none;
  cursor: pointer;
}

.rankings-container {
  padding: 1em;
  background: #f9f9f9;
  margin-right: 20px; /* Adjust the value as needed */
  align-content: end;
}

.form-container {
  padding: 1em;
  background: #ffffff;
}

form input,
form button {
  display: block;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
}

form button {
  background: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.error {
  color: red;
}

.error-message {
  color: red;
  margin: 10px 0;
}


/* Updated Footer Styles */
footer {
  background: #ffc107;
  color: white;
  text-align: center;
  padding: 1em 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 1em; /* Adjust the spacing between links */
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Additional styles to ensure the main content takes up the remaining space */
.App {
  flex: 1;
}
/* New styles for AuthPage */
.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form div {
  margin-bottom: 15px;
}

.auth-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.auth-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.auth-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.auth-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ffc107;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #b3b300;
}

.auth-toggle-button {
  background-color: #6c757d;
}

.auth-toggle-button:hover {
  background-color: #5a6268;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  margin-right: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  z-index: 1001; /* Ensure links are clickable */
}

/* Additional styles for external link */
.nav-links a[rel="noopener noreferrer"] {
  color: white; /* Adjust color to match your theme */
}

/* Styles for the "Fair Ranker" link */
.fair-ranker-link {
  background-color: #ffc107; /* Blue background */
  color: white; /* White text */
  padding: 0.5em 1em; /* Padding for better appearance */
  border-radius: 5px; /* Rounded corners */

  font-weight: bold; /* Bold text */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.fair-ranker-link:hover {
  background-color: #e7ae03; /* Darker blue on hover */
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-icon {
    display: block;
    color: white;
    font-size: 1.5em;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #ffc107;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding-top: 2em;
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links li {
    margin: 1em 0;
    width: 100%;
    text-align: center;
  }

  .nav-links .nav-button {
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }

  .nav-links .nav-button.logout-button {
    background-color: #ffb900;
    border-radius: 5px;
    padding: 0.5em 1em;
  }
}

.power-of-compounding {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.power-of-compounding h4 {
  text-align: center;
  margin-bottom: 20px;
}

.power-of-compounding canvas {
  margin-top: 20px;
}


.custom-button {
  background-color: #ff8307!important; /* Green background */
  color: white !important;              /* White text */
  border: none !important;              /* Remove any borders */
  padding: 6px 12px !important;         /* Adjust padding for button size */
  font-size: 0.8rem !important;         /* Adjust font size */
  margin-right: 8px !important;         /* Add some margin */
}

.custom-button:hover {
  background-color: #ffc107 !important; /* Darker green on hover */
}

.custom-button:last-child {
  margin-right: 0 !important; /* Remove right margin for the last button */
}

/* Scoped styles for elements within .content-list-page */
/* Scoped styles for elements within .content-list-page */
.content-list-page {
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  min-height: 100vh; 
  padding: 20px; 
}

#content-list {
  list-style-type: none;
  width: 100%; 
  max-width: 700px; 
  margin: 0px; 
  padding: 0; 
}

.content-item {
  display: flex;
  align-items: center;
  justify-content: start; 
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.content-rank {
  font-size: 24px;
  color: #151101;
  margin-right: 30px; 
}

.content-image {
  width: 100px;
  height: 80px;
  object-fit: cover;
  margin-right: 25px;
}

.content-info {
  flex-grow: 1;
  margin-right: auto;
}

.content-info h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.content-info p {
  margin: 5px 0;
  color: #666;
}

.content-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
  margin-left: auto; 
}

.content-vote-up,
.content-vote-down {
  background-color: white; 
  color: rgb(21, 11, 55);
  border: none;
  font-size: 35px;
  margin-left: 20px; 
  cursor: pointer;
}

.content-vote-up:hover,
.content-vote-down:hover {
  color: #e85e5e;
}

/* Highlight the voted button */
.voted {
  color: #4caf50; /* Green highlight for the voted button */
}

.votes-count {
  display: block;
  margin-left: 4px;
  font-size: 12px;
}

.item-submission-form {
  margin-top: 20px;
}

.submission-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px; /* Space between the input fields and button */
}

.item-name-input {
  width: 150px; /* Smaller width for item name */
  height: 30px; /* Same height for all elements */
  padding: 8px; /* Padding to ensure input text doesn't touch the edges */
  font-size: 14px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.description-input {
  width: 600px; /* Larger width for description */
  height: 30px; /* Same height as item name */
  padding: 8px; /* Padding for textarea */
  font-size: 14px;
  resize: none; /* Prevent resizing of textarea */
  box-sizing: border-box; /* Same padding and border consideration */
}

.submit-button {
  width: 100px; /* Larger width for description */
  height: 30px; /* Same height as input fields */
  padding: 5px 10px;
  font-size: 14px;
  background-color: #4CAF50; /* Green background */
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .submission-form {
    flex-direction: column; /* Stack inputs vertically */
    align-items: stretch; /* Make sure items take full width */
    
  }

  .item-name-input, .description-input, .submit-button {
    width: 80%; /* Full width for all items */
    margin-top: 4px; 
    margin-bottom: 4px;
  }

  .submit-button {
    margin-top: 4px; /* Add margin to the top for spacing */
  }
}

@media (max-width: 480px) {
  .item-name-input, .description-input, .submit-button {
    height: 35px; /* Smaller height for smaller screens */
  }

  .submit-button {
    font-size: 14px;
  }

  .login-note {
    font-size: 10px; /* Smaller font for smaller screens */
  }

  .content-vote-up,
.content-vote-down {
  background-color: white; 
  color: rgb(21, 11, 55);
  border: none;
  font-size: 25px;
  margin-left: 10px; 
  cursor: pointer;
}
}

.admin-dashboard-container {
  margin: 20px;
}

.admin-dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-dashboard-table th, .admin-dashboard-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.admin-dashboard-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.admin-dashboard-table tr:hover {
  background-color: #f9f9f9;
}